<template>
  <a-skeleton v-if="detailLoading" />
  <div v-else>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" tab="订单详情">
        <a-form :label-col="labelCol" :wrapper-col="wrapperCol">
          <a-row>
            <a-col :span="12">
              <a-form-item label="订单号">
                <span class="detail-text">{{ orderRecord.orderNumber }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="签单机构">
                <span class="detail-text">{{ institutions.name }}</span>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="签单时间">
                <span class="detail-text">{{ orderRecord.signingTime.split('T')[0] }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="客户姓名">
                <span class="detail-text">{{ institutionsCustomerRelationship.name }}</span>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="服务项目">
                <span class="detail-text">{{ serviceItem || '-' }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="订单归属人">
                <span class="detail-text"> {{ attributablePerson.name }}</span>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="订单跟进人">
                <span class="detail-text">{{ followUpPeople ? followUpPeople.name : '' }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="订单金额(元)">
                <span class="detail-text">￥{{ orderRecord.orderAmount }}</span>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="已付金额(元)">
                <span class="detail-text">￥{{ orderRecord.amountPaid }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="支付状态">
                <a-tag color="gray" v-if="orderRecord.paymentStatus === 'REFUND_COMPLETE'">退款完成</a-tag>
                <a-tag color="red" v-else-if="orderRecord.paymentStatus === 'REFUND_IN_PROCESS'">退款中</a-tag>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="取消原因">
                <span class="detail-text">{{ orderRecord.reasonForCancellation }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="取消时间">
                <span class="detail-text">{{ orderRecord.cancelTime.split('T')[0] }}</span>
              </a-form-item>
            </a-col>

          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="应退金额(元)">
                <span class="detail-text">￥{{ orderRecord.amountToBeRefunded }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="已退金额(元)">
                <span class="detail-text">￥{{ orderRecord.refundedAmount }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="退款完成时间">
                <span class="detail-text">{{ orderRecord.refundCompletionTime ?
                  orderRecord.refundCompletionTime.split('T')[0] : '' }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="24">
              <a-form-item label="订单情况" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
                <span class="detail-text">{{ orderRecord.orderStatus }}</span>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="收支记录" force-render>
        <table-page :config="config" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import TablePage from "@/components/TablePage";
import { get } from "@/utils/http";

export default {
  components: { TablePage },
  props: ['selectedRows'],
  name: "institutionsCustomerDetail",
  data() {
    return {
      detailLoading: false,
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      attributablePerson: null,
      followUpPeople: null,
      institutions: null,
      orderRecord: null,
      institutionsCustomerRelationship: null,
      institutions: null,
      activeKey: "1",
      serviceItem: '',
      config: {
        dataUrl: `/order/institutionsCancelledOrders/transaction?id=${this.selectedRows[0].id}`,
        filter: {
          controls: []
        },

        /* 表格操作 */
        actions: [
        ],

        columns: [
          {
            key: 'paymentType',
            title: '类型',
            type: 'enum',
            config: {
              constants: {
                PAYMENT: {
                  text: '支付',
                  color: 'orange'
                },
                REFUND: {
                  text: '退款',
                  color: 'green'
                }
              }
            }
          },
          {
            key: 'time',
            title: '收支时间',
            type: 'datetime'
          },
          {
            key: 'amount',
            title: '金额',
            config: {
              format: 'currency'
            }
          },
          {
            key: 'operator.name',
            title: '操作人',
          },
          {
            key: 'createdDate',
            title: '操作时间',
            type: 'datetime'
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: ['id'],
          /* 默认排序 */
          defaultSort: '-id'
        },

        /* 表格行操作 */
        rowActions: []
      }
    }
  },
  created() {
    this.getDetail(this.selectedRows[0].id)
  },
  methods: {
    async getDetail(id) {
      this.detailLoading = true;
      try {
        let res = await get("/order/institutionsCancelledOrders/details", { id: id })
        this.attributablePerson = res.attributablePerson
        this.followUpPeople = res.followUpPeople
        this.orderRecord = res.orderRecord
        this.institutions = res.institutions
        this.institutionsCustomerRelationship = res.institutionsCustomerRelationship
        this.serviceItem = res.serviceItem
      } catch (e) {
        this.$message.error(e.message)
      }
      this.detailLoading = false;
    }
  }
}
</script>

<style scoped>
.detail-title {
  font-weight: 550;
}


.ant-col {
  margin-top: 20px;
}

.user_image {
  max-width: 224px;
  max-height: 126px;
}
</style>
